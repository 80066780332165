<template>
  <div id="financialmanagement">
    <div class="top-box">
      <div class="top-1" style="border-left: 4px solid #ff7e00">
        <div class="top-2">
          <p class="top-3">总收入</p>
          <p class="top-4">￥{{IncomeTotalPrice || 0}}</p>
        </div>
        <img
          style="width: 60px; height: 60px; align-self: center"
          src="../../assets/cwTP/1.png"
          alt=""
        />
      </div>
      <div class="top-1" style="border-left: 4px solid #8565ff">
        <div class="top-2">
          <p class="top-3">总收入</p>
          <p class="top-4">￥{{ExpendTotalPrice || 0}}</p>
        </div>
        <img
          style="width: 60px; height: 60px; align-self: center"
          src="../../assets/cwTP/2.png"
          alt=""
        />
      </div>
      <div class="top-1" style="border-left: 4px solid #ff7e00">
        <div class="top-2">
          <p class="top-3">每日期初余额</p>
          <p class="top-4">￥0</p>
        </div>
        <img
          style="width: 60px; height: 60px; align-self: center"
          src="../../assets/cwTP/3.png"
          alt=""
        />
      </div>
      <div class="top-1" style="border-left: 4px solid #ff566a">
        <div class="top-2">
          <p class="top-3">每日期末余额</p>
          <p class="top-4">￥0</p>
        </div>
        <img
          style="width: 60px; height: 60px; align-self: center"
          src="../../assets/cwTP/4.png"
          alt=""
        />
      </div>
    </div>
    <div class="nr-box">
      <div class="nr-left">
        <div class="flex jc-b al-c">
          <p class="left-p">订单</p>
          <div class="date-time flex">
        <el-date-picker
          v-model="date"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="changeTime">
        </el-date-picker>
        <p style="width: 50px"></p>
        <el-tabs v-model="activeName" @tab-click="tabChange">
          <el-tab-pane
            v-for="(item, index) in tabslist"
            :key="index"
            :label="item"
            :value="index"
          ></el-tab-pane>
        </el-tabs>
        </div>
        </div>
     
        <ul class="left-ul">
          <li class="left-li">
            <img
              style="width: 50px; height: 50px; margin-right: 10px"
              src="../../assets/gwTP/5.png"
              alt=""
            />
            <div class="left-li-div">
              <p style="color: #434343; font-size: 18px">{{timeOrderInfo.income_num || 0}}</p>
              <p style="font-size: 14px; color: #808080">购买数量</p>
            </div>
          </li>
          <li class="left-li">
            <img
              style="width: 50px; height: 50px; margin-right: 10px"
              src="../../assets/cwTP/6.png"
              alt=""
            />
            <div class="left-li-div">
              <p style="color: #434343; font-size: 18px">{{timeOrderInfo.income || 0}}</p>
              <p style="font-size: 14px; color: #808080">付款金额</p>
            </div>
          </li>
          <li class="left-li">
            <img
              style="width: 50px; height: 50px; margin-right: 10px"
              src="../../assets/gwTP/7.png"
              alt=""
            />
            <div class="left-li-div">
              <p style="color: #434343; font-size: 18px">{{timeOrderInfo.transfer_num || 0}}</p>
              <p style="font-size: 14px; color: #808080">退款数量</p>
            </div>
          </li>
          <li class="left-li">
            <img
              style="width: 50px; height: 50px; margin-right: 10px"
              src="../../assets/cwTP/8.png"
              alt=""
            />
            <div class="left-li-div">
              <p style="color: #434343; font-size: 18px">{{timeOrderInfo.transfer || 0}}</p>
              <p style="font-size: 14px; color: #808080">退款金额</p>
            </div>
          </li>
        </ul>
        <div class="z-left-div">
          <div class="left-box">
            <p class="z-left-p">课程</p>
            <ul class="z-left-ul">
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  购买数量
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.study_card_num || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  付款金额
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.study_card || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款数量
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款金额
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
            </ul>
          </div>
          <div class="left-box">
            <p class="z-left-p">会员卡</p>
            <ul class="z-left-ul">
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  购买数量
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.member_num || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  付款金额
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.member || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款数量
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款金额
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
            </ul>
          </div>
          <div class="left-box">
            <p class="z-left-p">消费券</p>
            <ul class="z-left-ul">
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  购买数量
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.coupon_num || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  付款金额
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.coupon || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款数量
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款金额
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
            </ul>
          </div>
          <div class="left-box">
            <p class="z-left-p">权益点</p>
            <ul class="z-left-ul">
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  购买数量
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.point_num || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  付款金额
                </p>
                <p style="font-size: 18px; color: #434343">{{timeOrderInfo.point || 0 }}</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款数量
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款金额
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
            </ul>
          </div>
          <div class="left-box">
            <p class="z-left-p">休闲生活</p>
            <ul class="z-left-ul">
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  购买数量
                </p>
                <p style="font-size: 18px; color: #434343">0</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  付款金额
                </p>
                <p style="font-size: 18px; color: #434343">0</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款数量
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
              <li class="z-left-li">
                <p style="color: #808080; font-size: 14px; margin-bottom: 10px">
                  退款金额
                </p>
                <p style="font-size: 18px; color: #434343">-</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="nr-right">
        <div class="nr-right-s">
          <p style="margin: 0 0 30px 20px; color: #434343; font-size: 18px">
            测试
          </p>
          <div style="padding: 0 50px">
            <el-button
              type="warning"
              style="color: #ffffff;background-color: #ff7e00;margin-right: 10px; "
              >查询</el-button
            >
            <el-button type="danger" plain>删除</el-button>
            <div class="right-s">
              
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  @selection-change="handleSelectionChange"
                  style="width: 100%; margin-top: 20px"
                >
                  <el-table-column type="selection"></el-table-column>
                  <el-table-column prop="name" label="序号"> </el-table-column>
                  <el-table-column prop="name" label="类别"> </el-table-column>
                  <el-table-column
                    prop="address"
                    label="名称"
                  ></el-table-column>
                  <el-table-column
                    prop="address"
                    label="金额"
                  ></el-table-column>
                  <el-table-column
                    prop="address"
                    label="备注"
                  ></el-table-column>
                </el-table>
        

              <div class="right-s-1">
                <p style="margin-top: 170px">
                  <img
                    style="width: 16px; height: 16px; margin: 0 10px"
                    src="../../assets/yh1.png"
                    alt=""
                  /><img
                    style="width: 16px; height: 16px"
                    src="../../assets/yh1.png"
                    alt=""
                  />
                </p>
              </div>
            </div>
            <paging-fy
              @currentPageChange="currentPageChange"
              :currentPage="1"
              :total="12"
            ></paging-fy>
          </div>
        </div>
        <div class="nr-right-x">
          <div class="x-left">
            <p
              style="margin: 28px 0 40px 20px; font-size: 18px; color: #434343"
            >
              支出
            </p>
            <ul class="x-left-ul">
              <li class="x-left-li">
                <p style="font-size: 18px; color: #434343; margin-bottom: 12px">
                  ￥{{ExpendTotal.settlement || 0}}
                </p>
                <p style="color: #808080; font-size: 14px">机构结算</p>
              </li>
              <li class="x-left-li">
                <p style="font-size: 18px; color: #434343; margin-bottom: 12px">
                  ￥{{ExpendTotal.coupon || 0}}
                </p>
                <p style="color: #808080; font-size: 14px">小店核销</p>
              </li>
              <li class="x-left-li">
                <p style="font-size: 18px; color: #434343; margin-bottom: 12px">
                  ￥{{ExpendTotal.transfer || 0}}
                </p>
                <p style="color: #808080; font-size: 14px">佣金结算</p>
              </li>
            </ul>
          </div>
          <div class="x-right">
            <p style="font-size: 18px; color: #434343; margin: 28px 0 0 20px">
              其它
            </p>
            <div style="text-align: center">
              <img style="width: 88px; height: 82px" src="" alt="" />
              <p style="font-size: 14px; color: #d8d8d8">暂无~</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      activeName: 0,
      tabslist: ["今日", "本周", "本月"],
      tabName:'今日',
      pickerOptions: {
          shortcuts: [{
            text: '最近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      date: "",
      start_time:new Date().Format('yyyy-MM-dd') + " 00:00:00",
      timeOrderInfo:{},
      IncomeTotal:{
        study_card:0,
        point:0,
        member:0,
        coupon:0
      },
      IncomeTotalPrice:0,
      ExpendTotal:{
        refund:0,
        coupon:0,
        study_card:0,
      },
      ExpendTotalPrice:0,
      todayTotal:{
        income:0,
        expend:0
      }
    };
  },
  created() {
    this.assets_total()
  },
  methods: {
      // 时间选择
      changeTime(){
      this.activeName= "4"
      this.assets_total();
    },
      // tab切换
      tabChange(val){
      this.date = []
      console.log(val.label);
      this.tabName = val.label
      switch (val.label) {
        case "今日":
          this.start_time=new Date().Format('yyyy-MM-dd') + " 00:00:00"
          break;
          case "本周":
          this.start_time= this.getNowWeek()
          break;
          case "本月":
          this.start_time= this.getCurrentMonthFirst()
          break;
        default:
          break;
      }
    this.assets_total()
    },
    //本月
     getCurrentMonthFirst(){
        var date=new Date();
        console.log(date.setDate(1));
       return new Date(date.setDate(1)).Format("yyyy-MM-dd")+' 00:00:00';
    },
      // 获取本周
      getNowWeek(s) {
      let curDay = s ? new Date(s).getDay() : new Date().getDay();
      let start = s ? new Date(s).getTime() : new Date().getTime();
      let week = [];
      for (let i = 1-curDay; i < 8-curDay; i++) {
        let time = new Date(start + i * 1000 * 24 * 60 * 60);
        let data = {
          week: time.getDay(),
          day: time.getDate(),
          time: time.format("yyyy-MM-dd"),
        };
        week[data.week] = data;
      }
      return week[1].time + ' 00:00:00';
    },
    mechanism_count(){
      let url = "user/mastermechanism/masterMechanismCount";
      this.$axios
        .get(url)
        .then((res) => {
          console.log(res);
          // this.userCount = res.data.data
        });
    },
     //总资产统计
     assets_total() {
      let url = "user/rechargerecord/totalAssetsCount";
      let params = {
        statistics_time:  new Date().Format('yyyy-MM-dd hh:mm:ss'),
        start_time : this.start_time,
        end_time : new Date().Format('yyyy-MM-dd') + " 24:00:00" 
      }
      if(this.date.length>0){
        params.start_time = this.date[0]+ " 00:00:00"
        params.end_time = this.date[1]+ " 24:00:00"
      }
      this.$axios
        .get(url, { params: params })
        .then((res) => {
          let result = res.data.data
          let resTotal= result.statisticsTotal
          this.IncomeTotalPrice = 0
          this.ExpendTotalPrice = 0 
          resTotal.forEach(item=>{
            switch (item.rcharge_type) {
               //收入
              case "study_card":
                this.IncomeTotal.study_card = item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
              case "point":
                this.IncomeTotal.point =item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
              case "member":
                this.memberInfo = item
                this.IncomeTotal.member =item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
              case "coupon":
                this.IncomeTotal.coupon =item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
                 //支出
              case "结算":
                this.ExpendTotal.study_card = item.total_Acount
                this.ExpendTotalPrice += item.total_Acount
                break;
              case "商户券核销":
                this.ExpendTotal.coupon = item.total_Acount
                this.ExpendTotalPrice += item.total_Acount
                break;
              case "transfer": //提现
                this.ExpendTotal.transfer = item.total_Acount
                this.ExpendTotalPrice += item.total_Acount
                break;   
              default:
                break;
            }
          })
          this.IncomeTotalPrice = this.IncomeTotalPrice.toFixed(2)
          this.ExpendTotalPrice =  this.ExpendTotalPrice.toFixed(2)
          //时间段数据
          let todatInfo = result.statisticsToday
          this.timeOrderInfo.income = 0
          this.timeOrderInfo.expend = 0
          this.timeOrderInfo.income_num = 0
          todatInfo.forEach(item=>{
            switch (item.rcharge_type) {
               //收入
              case "study_card":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.income_num += item.total_num
                this.timeOrderInfo.study_card = item.total_Acount
                this.timeOrderInfo.study_card_num = item.total_num
                break;
              case "point":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.income_num += item.total_num
                this.timeOrderInfo.point = item.total_Acount
                this.timeOrderInfo.point_num = item.total_num
                break;
              case "member":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.income_num += item.total_num
                this.timeOrderInfo.member = item.total_Acount
                this.timeOrderInfo.member_num = item.total_num
                break;
              case "coupon":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.income_num += item.total_num
                this.timeOrderInfo.coupon = item.total_Acount
                this.timeOrderInfo.coupon_num = item.total_num
                break;
                 //支出
              case "结算":
                this.timeOrderInfo.expend += item.total_Acount
                this.timeOrderInfo.settlement = item.total_Acount
                break;
              case "商户券核销":
                this.timeOrderInfo.expend += item.total_Acount
                break;
              case "transfer":
                this.timeOrderInfo.expend += item.total_Acount
                this.timeOrderInfo.transfer = item.total_Acount
                this.timeOrderInfo.transfer_num = item.total_num
                break;   
              default:
                break;
            }
          })
          this.timeOrderInfo.income = this.timeOrderInfo.income.toFixed(2)
          this.timeOrderInfo.expend = this.timeOrderInfo.expend.toFixed(2)
        });
    },
    currentPageChange() {},
    handleSelectionChange(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="less">
.cell {
  text-align: center !important;
}
.date-time{
  box-sizing: border-box;
  padding-right: 10px;
  @media screen and(max-width: 768px) {
      flex-direction: column;
    }
}
#financialmanagement {
  .top-box {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .top-1 {
      background-color: #ffffff;
      height: 120px;
      flex: 1;
      display: flex;
      padding-right: 50px;
      &:not(:last-child) {
        margin-right: 20px;
      }
      @media screen and(max-width: 1280px) {
        width: 42%;
        flex: none;
        &:not(:last-child) {
          margin-right: 0px;
          margin-bottom: 20px;
        }
      }
      @media screen and(max-width: 768px) {
        width: 100%;
        flex: none;
      }
      .top-2 {
        flex: 1;
        padding-left: 20px;
        .top-3 {
          margin: 30px 0 15px 0;
          color: #434343;
          font-size: 16px;
        }
        .top-4 {
          color: #333333;
          font-size: 24px;
        }
      }
    }
  }
  .nr-box {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .nr-left {
      margin-right: 20px;
      width: 48%;
      flex: 1;
      background-color: #ffffff;
      @media screen and(max-width: 1280px) {
        width: 100%;
        margin-right: 0;

      }
      .left-p {
        padding: 30px 0 30px 20px;
        color: #434343;
        font-size: 18px;
      }
      .left-ul {
        display: flex;
        padding-left: 70px;
        flex-wrap: wrap;
        .left-li {
          flex: 1;
          @media screen and(max-width:768px) {
             width: 100vw;
             flex:none;
          }
          display: flex;
          .left-li-div {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
          }
        }
      }
      .z-left-div {
        margin-top: 20px;
        padding: 0 50px 0 25px;
        display: flex;
        flex-wrap: wrap;
        .left-box {
          margin-left: 25px;
          width: calc(50% - 27px);
          border: 1px solid #eaeaea;
          @media screen and(max-width: 1280px){
            width: 100%;
          }
          margin-bottom: 10px;
          .z-left-p {
            margin: 20px 0 20px 15px;
          }
          .z-left-ul {
            display: flex;
            flex-wrap: wrap;
            padding-left: 25px;
            .z-left-li {
              min-width: 40%;
              flex: 1;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .nr-right {
      flex: 1;
      .nr-right-s {
        @media screen and(max-width:768px) {
          width: 90vw;
        }
        .right-s {
          display: flex;
          height: 380px;
          flex-wrap: wrap;
          .right-s-1 {
            width: 60px;
            //    @media screen and(max-width:768px) {
            //      width: 100%;
            // }
          }
        }
        background-color: #ffffff;
        height: 530px;
        padding-top: 30px;
      }
      .nr-right-x {
        margin-top: 20px;
        height: 184px;
        display: flex;
        flex-wrap: wrap;
        .x-left {
          background-color: #ffffff;
          width: 65%;
          margin-right: 20px;
          @media screen and(max-width:768px) {
            width: 100%;
            margin-right: 0;
          }
          .x-left-ul {
            display: flex;
            padding: 0 50px;
            flex-wrap: wrap;
            .x-left-li {
              flex: 1;
            }
          }
        }
        .x-right {
          flex: 1;
          background-color: #ffffff;
        }
      }
    }
  }
}
</style>
